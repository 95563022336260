import './style.css';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as dat from 'lil-gui';
import {
  DirectionalLight,
  DirectionalLightHelper,
  Group,
  Raycaster,
  SpotLight,
  TetrahedronGeometry,
} from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';

// const gui = new dat.GUI()

// const loadingBarElement = document.querySelector('.loading-bar')
// const loadingBarElementWrapper = document.querySelector('.loading-wrapper')

const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // Wait a little
    window.setTimeout(() => {
      // Animate overlay
      gsap.to(overlayMaterial.uniforms.uAlpha, {
        duration: 3,
        value: 0,
        delay: 1,
      });

      // Update loadingBarElement
      // loadingBarElement.classList.add('ended')
      // loadingBarElementWrapper.classList.add('ended')
      // loadingBarElement.style.transform = ''
    }, 500);
  }
);

const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath('/draco/');

const gltfLoader = new GLTFLoader();
gltfLoader.setDRACOLoader(dracoLoader);

//Loaders
const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager);
const debugObject = {};

// Canvas
const canvas = document.querySelector('canvas');

// Scene
const scene = new THREE.Scene();
scene.background = new THREE.Color(0xffffff);
// scene.fog = new THREE.Fog(0xFFFFFF, -4, 10);

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
  // wireframe: true,
  transparent: true,
  uniforms: {
    uAlpha: {
      value: 1,
    },
  },
  vertexShader: `
         void main()
         {
             gl_Position = vec4(position, 1.0);
         }
     `,
  fragmentShader: `
         uniform float uAlpha;
 
         void main()
         {
             gl_FragColor = vec4(1.0, 1.0, 1.0, uAlpha);
         }
     `,
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

let canvasContainer = document.querySelector('.canvas-container');

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  alpha: true,
  antialias: true, //remove pixelated edges
});
// renderer.setSize(sizes.width, sizes.height)
renderer.setSize($(canvasContainer).width(), $(canvasContainer).height());
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.physicallyCorrectLights = true;
renderer.outputEncoding = THREE.sRGBEncoding; //Определяет выходную кодировку средства визуализации
renderer.toneMapping = THREE.ACESFilmicToneMapping; // or  THREE.CineonToneMapping etc.
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFShadowMap;

debugObject.clearColor = '#ffffff';
renderer.setClearColor(debugObject.clearColor);
canvasContainer.appendChild(renderer.domElement);
/**
 * Sizes
 */
const sizes = {
  width: canvas.width,
  height: canvas.height,
};

// window.addEventListener('resize', () => {
//     // Update camera
//     camera.aspect = window.innerWidth / window.innerHeight;
//     camera.updateProjectionMatrix()

//     // Update renderer
//     renderer.setSize(window.innerWidth, window.innerHeight);
//     renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
//     renderer.render(scene, camera);
// })

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  75,
  sizes.width / sizes.height,
  0.1,
  1000
);
camera.position.z = 2;
camera.position.y = 0;
camera.position.x = 0;

camera.lookAt(scene.position);

scene.add(camera);

// Controls
const controls = new OrbitControls(camera, document.body);
controls.enableDamping = true;

//controls zooming in and out on scroll
controls.enableZoom = false;
controls.enableRotate = false;

//controls rotation speed
// controls.rotateSpeed = 0.6;
controls.update();

// const axesHelper = new THREE.AxesHelper(10);
// scene.add(axesHelper);
// const size = 10;
// const divisions = 10;

// const gridHelper = new THREE.GridHelper( size, divisions );
// scene.add( gridHelper );

// gui.addColor(debugObject, 'clearColor')

//Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 1);
// gui.add(ambientLight, 'intensity').min(-5).max(5).step(1)
scene.add(ambientLight);

// Directional light
const directionalLight = new THREE.DirectionalLight(0xffffff, 7);
directionalLight.position.set(0, 1.9, 0.4);
scene.add(directionalLight);

directionalLight.shadow.radius = 8;

//points of interest
const raycaster = new Raycaster();

// gui.add(camera.position, 'x').min(-15).max(15).step(0.1).name('Camera position X Scene')
// gui.add(camera.position, 'y').min(-15).max(15).step(0.1).name('Camera position Y Scene')
// gui.add(camera.position, 'z').min(-15).max(15).step(0.1).name('Camera position Z Scene')

gltfLoader.load('/models/heart-model-2.glb', (gltf) => {
  gltf.scene.scale.set(0.03, 0.03, 0.03);
  gltf.scene.position.set(0, -1, 0);
  // gltf.scene.rotation.y = 3.2
  // gltf.scene.rotation.x = -0.17

  //   gsap.registerPlugin(ScrollTrigger);

  gltf.scene.traverse(function (node) {
    if (node.isMesh) {
      node.castShadow = true;
    }
  });
  // gui.add(gltf.scene.scale, 'x').min(-15).max(15).step(0.1).name('scale X')
  // gui.add(gltf.scene.scale, 'y').min(-15).max(15).step(0.1).name('scale Y')
  // gui.add(gltf.scene.scale, 'z').min(-15).max(15).step(0.1).name('scale Z')
  // gui.add(gltf.scene.rotation, 'x').min(-15).max(15).step(0.1).name('rotation X')
  // gui.add(gltf.scene.rotation, 'y').min(-15).max(15).step(0.1).name('rotation Y')
  // gui.add(gltf.scene.rotation, 'z').min(-15).max(15).step(0.1).name('rotation Z')
  // gui.add(gltf.scene.position, 'x').min(-15).max(15).step(0.1).name('position X')
  // gui.add(gltf.scene.position, 'y').min(-15).max(15).step(0.1).name('position Y')
  // gui.add(gltf.scene.position, 'z').min(-15).max(15).step(0.1).name('position Z')
  scene.add(gltf.scene);
});

// Update all materials
const updateAllMaterials = () => {
  //  console.log(scene)
  scene.traverse((child) => {
    if (
      child instanceof THREE.Mesh &&
      child.material instanceof THREE.MeshStandardMaterial
    ) {
      child.material.envMap = environmentMap;
      // child.material.roughness = 0,
      // child.transmission = 1,
      // child.thickness = 2
      // child.material.transparent = true
      // child.material.opacity = 0.4
      child.material.envMapIntensity = debugObject.envMapIntensity;

      // TweenLite.to(child.material, 2, {opacity: 0.5, transparent: true});
    }
  });
};

debugObject.clearColor = '#FFFFFF';
renderer.setClearColor(debugObject.clearColor);
// gui.addColor(debugObject, 'clearColor')

/**
 * Animate
 */
const tick = () => {
  // Update controls
  controls.update();

  // Render
  renderer.render(scene, camera);

  // Call tick again on the next frame
  window.requestAnimationFrame(tick);
};

tick();

window.addEventListener('mousemove', (event) => {
  camera.position.x = (event.clientX / window.innerWidth) * 2 - 1;
  camera.position.y = (event.clientY / window.innerHeight) * 2 - 1;
});

// gui.add(ambientLight, 'intensity').min(-10).max(10).step(1)
// gui.add(ambientLight.position, 'x').min(- 10).max(10).step(0.1)
// gui.add(ambientLight.position, 'y').min(- 10).max(10).step(0.1)
// gui.add(ambientLight.position, 'z').min(- 10).max(10).step(0.1)
